.legit-chat .single-message {
  padding: 0.5rem 0.8rem;
  border-radius: 0.6rem;
  margin: 0.5rem 0;
  max-width: 40%;
}

.legit-chat .messages-container {
  min-height: 70vh;
  max-height: 70vh;
  overflow-y: scroll;
  scrollbar-width: 0;
  display: flex;
  flex-direction: column;
}

.legit-chat .single-message.sent {
  background: #e53e3e;
  color: white;
  margin-right: 0;
  align-self: flex-end;
}

.legit-chat .single-message.received {
  background: white;
  color: black;
}

.active-link {
  color: #e53e3e;
}